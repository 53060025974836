var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-flex align-items-center min-vh-100" },
    [
      _c(
        "v-row",
        { staticClass: "w-100 justify-content-center" },
        [
          _c("v-col", { attrs: { md: "6" } }, [
            _c(
              "div",
              { staticClass: "w-100" },
              [
                _c("div", { staticClass: "clearfix" }, [
                  _c("h1", { staticClass: "float-left display-3 ms-4" }, [
                    _vm._v("404"),
                  ]),
                  _c("h4", { staticClass: "pt-3" }, [
                    _vm._v("صفحه مورد نظر یافت نشد"),
                  ]),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(
                      "احتمالا لینکی که قصد دسترسی به آن را دارید حذف شده است و یا تغییر کرده است"
                    ),
                  ]),
                ]),
                _c("v-input", {
                  staticClass: "mb-3",
                  attrs: { placeholder: "What are you looking for?" },
                  scopedSlots: _vm._u([
                    {
                      key: "prepend-content",
                      fn: function () {
                        return [
                          _c("v-icon", {
                            attrs: { name: "cil-magnifying-glass" },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "info" },
                              on: {
                                click: function ($event) {
                                  return _vm.gotoHome()
                                },
                              },
                            },
                            [_vm._v("بازگشت به صفحه نخست")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }